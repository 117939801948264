import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICheckList } from "types/formTypes";
import { IClient } from "types/tablesTypes";
import { IUserData } from "types/userTypes";

interface UserState {
    jwt: string | null;
    userData: IUserData | null; 
    clients: IClient[];
    checkList: ICheckList[];
}

const initialState: UserState = {
    jwt: null, 
    userData: null,
    clients: [],
    checkList: []
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string | null>) => {
            state.jwt = action.payload; 
        },
        setUser: (state, action: PayloadAction<IUserData>) => {
            state.userData = action.payload; 
        },
        setCheckList: (state, action: PayloadAction<ICheckList[]>) => {
            state.checkList = action.payload; 
        },
        logout: (state) => {
            state.jwt = null; 
            state.userData = null; 
        },
        setClients: (state, action: PayloadAction<IClient[]>) => {
            state.clients = action.payload;
        },
        addClient: (state, action: PayloadAction<IClient>) => {
            state.clients.push(action.payload);
        },
    }
})

export const { setToken, setUser, logout, setClients, addClient, setCheckList } = userSlice.actions;
export default userSlice.reducer;
