import React from 'react';
import { Client, Dashboard, Exit, Logo, Progress, Settings, Subscription, Transaction } from 'assets';
import styles from './Sidebar.module.css';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useNavigate, useLocation } from 'react-router-dom';
import { logout } from 'store/user/userSlice';

export default function Sidebar() {
    const userData = useAppSelector((state) => state.user.userData);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch()

    const handleItemClick = (id: string) => {
        if (id === 'exit') {
            dispatch(logout())
            localStorage.clear()
        }
        navigate(`/${id}`);
    };

    const menuItems = [
        { id: 'dashboard', icon: <Dashboard />, label: 'מסך ניהול' },
        { id: 'transactions', icon: <Transaction />, label: 'עסקאות' },
        { id: 'clients', icon: <Client />, label: 'לקוחות' },
        { id: 'report', icon: <Progress />, label: 'מעקב שכ”ט' },
        { id: 'settings', icon: <Settings />, label: 'הגדרות' },
        { id: 'packages', icon: <Subscription />, label: 'חבילות' },
        { id: 'textbox', icon: null, label: null },
        { id: 'exit', icon: <Exit />, label: 'יציאה' },
    ];

    return (
        <div className={styles.sidebar}>
            <Logo style={{ cursor: 'pointer' }} onClick={() => navigate('/dashboard')} />
            <div className={styles.sidebarContent}>
                {menuItems.map((item) =>
                    item.icon ? (
                        <div
                            key={item.id}
                            className={`${styles.sidebarItem} ${location.pathname.includes(`/${item.id}`) ? styles.active : ''}`}
                            onClick={() => handleItemClick(item.id)}
                        >
                            {React.cloneElement(item.icon, {
                                style: {
                                    fill: location.pathname.includes(`/${item.id}`) ? '#317289' : '#A3A5BA',
                                },
                            })}
                            <span className={styles.text} style={{ color: location.pathname.includes(`/${item.id}`) ? '#317289' : '#A3A5BA' }}>
                                {item.label}
                            </span>
                        </div>
                    ) : (
                        <div key={item.id} className={styles.box}>
                            <span>מספר לקוח</span>
                            <span>{userData?.id}</span>
                            <span className='mt-3'>מנוי נוכחי</span>
                            <span>פרימיום</span>
                        </div>
                    )
                )}
            </div>
        </div>
    );
}
