import React from 'react';
import { Notification, UserDefault } from 'assets';
import { getWelcomeMessage } from 'utils/functions';
import styles from './Header.module.css';
import { useAppSelector } from 'store/hooks';
import { Search, Button } from 'components';
import { transactions } from 'utils/mockData';
import { useNavigate } from 'react-router-dom';
import { apiSearchTransaction } from 'services';

const Header = () => {
    const userData = useAppSelector((state) => state.user.userData);
    const navigate = useNavigate();

    const fetchTransactions = async (query: string) => {
        try {
            const response = await apiSearchTransaction(query)
            return response.data.map((item: any) => ({
                ...item.attributes,
                id: item.id
            }))
        } catch (error) {
            console.error('Error fetching transactions:', error);
            return [];
        }
    };

    return (
        <div style={{ height: '140px', }} className='d-flex pt-5 pl-5 justify-content-between'>
            <div style={{ width: '60%' }}>
                <div className="d-flex justify-content-between align-items-start">
                    <div className='d-flex gap-2 align-items-center' style={{ paddingRight: '30px' }}>
                        <div className={styles.userImage}>
                            {userData?.userImage ?
                                <img alt="user-img" src={userData?.userImage} />
                                :
                                <UserDefault />}
                        </div>
                        <div className='d-flex flex-column'>
                            <span className={styles.headerTitle}>{`${userData?.firstName} ${userData?.lastName}`}</span>
                            <span className={styles.headerSubtitle}>{getWelcomeMessage()}</span>
                        </div>
                    </div>
                    <button
                        onClick={() => navigate('/notifications')}
                        className="rounded-circle bg-white d-flex align-items-center justify-content-center" style={{ width: '65px', height: '65px' }}>
                        <Notification />
                    </button>
                </div>
            </div>
            <div className="d-flex flex-column justify-content-between" style={{ width: '38%' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                    <div style={{ flex: 0.7 }}>
                        <Search
                            placeholder="חיפוש עסקאות קיימות"
                            fetchData={fetchTransactions}
                            getLabel={(item) => `${item.street} ${item.houseNumber}, ${item.city}`}
                            onSearch={(transaction: any) => navigate(`/transactions/${transaction.id}`, { state: { transaction } })}
                        />
                    </div>
                    <div style={{ flex: 0.3 }}>
                        <Button onClick={() => navigate('/new-deal')} type="button" text="עסקה חדשה +" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
