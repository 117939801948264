import Card from 'components/Card';
import styles from './ResetPassword.module.css'
import { Logo } from 'assets';
import { CustomTextField, Button } from 'components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { VerifyCodeInputs } from 'types/formTypes';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { IResetPassword } from 'types/userTypes';
import { apiResetPassword } from 'services';
import { useState } from 'react';

const ResetPassword = () => {
    const { handleSubmit, control, formState: { errors }, setError } = useForm<IResetPassword>();
    const [message, setMessage] = useState('')

    const location = useLocation();
    const navigate = useNavigate()
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');


    const onSubmit: SubmitHandler<IResetPassword> = (data) => {
        const { password, passwordConfirmation } = data;

        apiResetPassword({ code, password, passwordConfirmation })
            .then(() => {
                setMessage('הסיסמה אופסה בהצלחה')
                setTimeout(() => {
                    navigate('/login')
                }, 2000);
            }).catch(err => {
                setMessage('שגיאה, אנא נסה מאוחר יותר')
            });
    };

    return (
        <div className={styles.container}>
            <Card>
                <Logo />
                <p className={styles.title}>הזינו את הסיסמה החדשה</p>
                <p className={styles.label}>הסיסמה צריכה להכיל לפחות שישה תווים</p>
                <form style={{ width: '60%' }} onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <CustomTextField
                            name="password"
                            fullWidth
                            type="password"
                            control={control}
                            rules={{ required: true }}
                        />
                        <CustomTextField
                            name="passwordConfirmation"
                            fullWidth
                            type="password"
                            control={control}
                            rules={{ required: true }}
                        />
                        <Button type="submit" text="איפוס סיסמה" />
                    </Box>
                </form>
                <p className='mt-5 text-danger'>{message}</p>
            </Card>
        </div>
    )

}


export default ResetPassword;
