import axios from "axios";
import { env } from "../env";
import { store } from "store";

const apiClient = axios.create({
    baseURL: env.API_URL, 
    headers: {
        "Content-Type": "application/json",
    },
});

apiClient.interceptors.request.use(
    (config) => {
        const token = store.getState().user.jwt;
        
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response?.status === 401) {
            console.error("Unauthorized! Redirecting to login...");
            window.location.href = "/login"; 
        }
        return Promise.reject(error);
    }
);

export default apiClient;
