// AuthDashboardLayout.tsx
import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Sidebar, Header } from 'components';

const AuthLayout = () => {
    return (
        <Box
            sx={{
                height: '100vh',
                background: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    boxSizing: 'border-box',
                    width: '100%',
                    borderRadius: '65px',
                    boxShadow: 3,
                    display: 'flex',
                    margin: '23px',
                    background: '#F1F3FF',
                    height: 'calc(100% - 46px)',
                    overflow: 'auto',
                }}
            >
                <Sidebar />
                <Box width="90%">
                    <Header />
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
};

export default AuthLayout;
