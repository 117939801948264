import React, { useState } from 'react';
import styles from './Checkbox.module.css';

interface CheckboxProps {
    label: React.ReactNode;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function Checkbox({ label, checked, onChange }: CheckboxProps) {


    return (
        <div className={styles.checkboxContainer}>
            <input
                type="checkbox"
                checked={checked}
                onChange={onChange}
                className={styles.customCheckbox}
            />
            {label}
        </div>
    );
};

