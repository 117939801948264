import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { TextField, InputAdornment, IconButton, TextFieldProps } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { NumericFormat } from 'react-number-format';

type CustomTextFieldProps = {
    name: string;
    control: any;
    rules?: any;
    defaultValue?: string;
    errorMessage?: string;
    showSign?: boolean;
} & TextFieldProps;

const CustomTextField: React.FC<CustomTextFieldProps> = ({
    name,
    control,
    rules,
    defaultValue = "",
    errorMessage,
    type = "text",
    showSign = false,
    ...rest
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

    const renderEndAdornment = () => {
        if (showSign) {
            return <InputAdornment position="end">₪</InputAdornment>;
        }
        if (type === "password") {
            return (
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                        edge="end"
                    >
                        {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                    </IconButton>
                </InputAdornment>
            );
        }
        return null;
    };

    const commonInputProps = {
        endAdornment: renderEndAdornment(),
        sx: {
            height: { xs: '40px', sm: '47px' },
            borderRadius: '15px',
        },
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field, fieldState }) => (
                showSign ? (
                    <NumericFormat
                        {...field}
                        {...rest}
                        customInput={TextField}
                        thousandSeparator=","
                        valueIsNumericString
                        error={fieldState.invalid}
                        variant="outlined"
                        InputProps={{
                            ...rest.InputProps,
                            ...commonInputProps,
                        }}
                        onValueChange={(values) => {
                            field.onChange(values.value);
                        }}
                    />
                ) : (
                    <TextField
                        {...field}
                        {...rest}
                        type={type === "password" ? (!showPassword ? "password" : "text") : type}
                        error={fieldState.invalid}
                        variant="outlined"
                        InputProps={{
                            ...rest.InputProps,
                            ...commonInputProps,
                        }}
                    />
                )
            )}
        />
    );
};

export default CustomTextField;
