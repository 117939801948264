import React, { useState, useEffect } from 'react';
import { Search as SearchIcon } from 'assets';
import styles from './Search.module.css';
import useDebounce from 'hooks/useDebounce';

type SearchProps<T> = {
    placeholder?: string;
    onSearch: (query: T) => void; 
    getLabel: (item: T) => string; 
    fetchData: (query: string) => Promise<T[]>; 
};

const Search = <T,>({ placeholder = 'Search...', onSearch, getLabel, fetchData }: SearchProps<T>) => {
    const [query, setQuery] = useState<string>('');
    const [filteredData, setFilteredData] = useState<T[]>([]);
    const debouncedQuery = useDebounce(query, 200);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    };

    const searchData = async (query: string) => {
        if (!query) {
            setFilteredData([]);
            return;
        }
        try {
            const data = await fetchData(query); 
            setFilteredData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (debouncedQuery) {
            searchData(debouncedQuery);
        } else {
            setFilteredData([]);
        }
    }, [debouncedQuery]);

    const handleSelect = (item: T) => {
        setQuery('');
        setFilteredData([]);
        onSearch(item); 
    };

    return (
        <div style={{ width: '100%', position: 'relative' }}>
            <form onSubmit={(e) => e.preventDefault()} style={{ display: 'flex', alignItems: 'center' }}>
                <SearchIcon style={{ position: 'absolute', right: '20px', top: '50%', transform: 'translateY(-50%)' }} />
                <input
                    type="text"
                    value={query}
                    onChange={handleChange}
                    placeholder={placeholder}
                    className={styles.searchInput}
                />
            </form>
            {filteredData.length > 0 && (
                <div className={styles.dropdown}>
                    {filteredData.map((item, index) => (
                        <div
                            key={index}
                            onClick={() => handleSelect(item)}
                            className={styles.dropdownItem}
                        >
                            {getLabel(item)} 
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Search;
