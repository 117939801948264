
export const endPoints = {
  LOGIN: `auth/local`,
  REGISTER: `/auth/local/register`,
  VERIFY: `/auth/verify-phone`,
  FORGOT_PASSWORD: `/auth/forgot-password`,
  RESET_PASSWORD: `/auth/reset-password`,
  CLIENT: `/clients`,
  COMPANY: `/companies`,
  USER: `/users`,
  PROPERTY_TYPE: `/property-types`,
  TRANSACTION: `/transactions`,
  PAYMENTS: `/transaction-payments`,
  UPLOAD: `/upload`,
  STEPS: `/payment-steps`,
  OTHER_CLIENT: `/other-side-clients`,
  CHECK_LIST: `/check-lists`,
  ORDER: `/orders`,
  PAYMENT: `/proxy/payment`
};
