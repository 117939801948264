export const env = {
  API_URL:
    process.env.REACT_APP_API_URL || 'https://backend.lawgical.co.il/api',
  BASE_URL:
    process.env.REACT_APP_BASE_URL || 'https://backend.lawgical.co.il',
  DOMAIN_URL:
    process.env.REACT_APP_DOMAIN_URL || 'https://app.lawgical.co.il',
};

// https://lawyer.iqdesk.xyz/api
