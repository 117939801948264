export const getWelcomeMessage = () => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();

    const time = hours * 100 + minutes;

    if (time >= 500 && time <= 1159) {
        return 'בוקר טוב :)';
    } else if (time >= 1200 && time <= 1459) {
        return 'צהריים טובים :)';
    } else if (time >= 1500 && time <= 1759) {
        return 'אחה"צ טובים :)';
    } else if (time >= 1800 && time <= 2159) {
        return 'ערב טוב :)';
    } else {
        return 'לילה טוב :)';
    }
}

export const addFee = (num: string | number) => {
    if (!num) {
        return '0'; 
    }

    const numericValue = typeof num === "string" ? parseFloat(num.replace(/,/g, '')) : num;
    const total = numericValue * 1.17;

    return total.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

export const formatNumberWithCommas = (value: string) => {
    if (!value) {
        return '0'; 
    }
    const numberValue = value.replace(/[^\d]/g, '');
    return numberValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};