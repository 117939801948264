import React from 'react';
import { Controller } from 'react-hook-form';
import { Select, MenuItem, FormControl, SelectProps, InputLabel } from '@mui/material';

type MenuItemType = {
    value: number | string;
    label: string;
};

type CustomSelectProps = {
    name: string;
    control: any;
    rules?: any;
    defaultValue?: string | number;
    errorMessage?: string;
    label?: string;
    menuItems: MenuItemType[];
} & SelectProps;

const CustomSelect: React.FC<CustomSelectProps> = ({
    name,
    control,
    rules,
    defaultValue,
    errorMessage,
    label,
    menuItems,
    ...rest
}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue || ''}
            rules={rules}
            render={({ field, fieldState }) => {
                const value = field.value ?? '';
                return (
                    <FormControl fullWidth error={!!errorMessage}>
                        <InputLabel sx={{ lineHeight: '15px', color: '#A3A5BA' }}>{label}</InputLabel>
                        <Select
                            error={fieldState.invalid}
                            {...field}
                            label={label}
                            value={value}
                            {...rest}
                        >
                            {menuItems.map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }}
        />
    );
};

export default CustomSelect;
