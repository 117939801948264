import { useEffect, useState } from "react";



const useDebounce = (value: string, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const delayTimer = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(delayTimer);
        };
    }, [value, delay]);

    return debouncedValue;
};

export default useDebounce;