import Button from 'components/Button';
import styles from './Modal.module.css'
import { Close } from 'assets';

const Modal = ({ title, data, secondButton = false, showBackground = true, handleSend, handleClose }: { data?: string, title?: string, secondButton?: boolean, showBackground?: boolean, handleSend: () => void; handleClose?: () => void }) => {
    return (
        <div style={{ backgroundColor: showBackground ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255' }} className={styles.modalOverlay} >
            <div className={styles.modalContent}>
                <button onClick={handleClose} className="d-flex align-self-start"><Close /></button>
                <p className={styles.title}>{title}</p>
                <div className={styles.scrollableView}>
                    {data && <div dangerouslySetInnerHTML={{ __html: data }}></div>}
                </div>
                <div className='d-flex'>
                    <div className={styles.sendButton}>
                        <Button onClick={handleSend} text="אישור" />
                    </div>
                    {secondButton && <div className={styles.sendButton}>
                        <Button onClick={handleClose} text="ביטול" />
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default Modal;