import React, { Suspense, lazy } from 'react';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { CircularProgress, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { persistor, store } from 'store';
import { PersistGate } from 'redux-persist/integration/react';
import { useAppSelector } from 'store/hooks';
import AuthLayout from 'layouts/AuthLayout';
import ForgotPassword from 'views/ForgotPassword';
import ResetPassword from 'views/ResetPassword';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';

const Login = lazy(() => import('views/Login'));
const Register = lazy(() => import('views/Register'));
const VerifyCode = lazy(() => import('views/VerifyCode'));
const Dashboard = lazy(() => import('views/Dashboard'));
const Transactions = lazy(() => import('views/Transactions'));
const TransactionDetails = lazy(() => import('views/TransactionDetails'));
const Clients = lazy(() => import('views/Clients'));
const NewDeal = lazy(() => import('views/NewDeal'));
const Settings = lazy(() => import('views/Settings'));
const Notifications = lazy(() => import('views/Notifications'));
const Report = lazy(() => import('views/Report'));
const Packages = lazy(() => import('views/Packages'));
const Payment = lazy(() => import('views/Payment'));

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme({
  direction: 'rtl',
  typography: {
    fontFamily: '"Assistant", sans-serif',
  },
});

const AuthRoutes = () => {
  const isAuthenticated = useAppSelector((state) => !!state.user.userData);
  return (
    <Routes>
      {!isAuthenticated ? (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verify-code" element={<VerifyCode />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </>
      ) : (
        <>
          <Route element={<AuthLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/transactions/:id" element={<TransactionDetails />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/report" element={<Report />} />
            <Route path="/packages" element={<Packages />} />
          </Route>

          <Route path="/payment" element={<Payment />} />
          <Route path="/new-deal" element={<NewDeal />} />

          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </>
      )}
    </Routes>
  );
};

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
              <Suspense fallback={<div className="loader-container"><CircularProgress /></div>}>
                <AuthRoutes />
              </Suspense>
            </BrowserRouter>
          </ThemeProvider>
        </CacheProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
