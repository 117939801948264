import Card from 'components/Card';
import styles from './ForgotPassword.module.css'
import { Logo } from 'assets';
import { CustomTextField, Button } from 'components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { VerifyCodeInputs } from 'types/formTypes';
import { Box } from '@mui/material';
import { IForgotPassword } from 'types/userTypes';
import { apiForgotPassword } from 'services';
import { useState } from 'react';

const ForgotPassword = () => {
    const { handleSubmit, control, formState: { errors }, setError } = useForm<IForgotPassword>();

    const [message, setMessage] = useState('')

    const onSubmit: SubmitHandler<IForgotPassword> = async (data) => {
        const { email } = data;

        setMessage('עבור איפוס סיסמה נשלח אליך קישור למייל ברגעים אלו')
        await apiForgotPassword({ email })

    };


    return (
        <div className={styles.container}>
            <Card>
                <Logo />
                <p className={styles.title}>עבור אימות סיסמה נשלח לך קישור לדוא"ל</p>
                <p className={styles.label}>הזינו כאן את הדוא"ל של המנוי</p>
                <form style={{ width: '60%' }} onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <CustomTextField
                            name="email"
                            fullWidth
                            placeholder='@'
                            type="text"
                            control={control}
                            rules={{ required: true }}
                        />
                        <Button type="submit" text="איפוס סיסמה" />
                    </Box>
                </form>
                <p className='mt-5 text-danger'>{message}</p>
            </Card>
        </div>
    )

}


export default ForgotPassword;
