import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';

type CustomTextAreaProps = {
    name: string;
    control: any;
    rules?: any;
    defaultValue?: string;
    errorMessage?: string;
} & TextFieldProps;

const CustomTextArea: React.FC<CustomTextAreaProps> = ({
    name,
    control,
    rules,
    defaultValue,
    errorMessage,
    ...rest
}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue || ""}
            rules={rules}
            render={({ field, fieldState }) => (
                <TextField
                    {...field}
                    {...rest}
                    multiline
                    fullWidth
                    rows={4}
                    variant="outlined"
                    error={fieldState.invalid}
                    InputProps={{
                        sx: {
                            borderRadius: '15px',
                            backgroundColor: '#F1F3FF',
                            padding: '10px', 
                        },
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none',
                            },
                        },
                        height: 'auto', 
                    }}
                />
            )}
        />
    );
};

export default CustomTextArea;
