import { endPoints } from "./urls"
import { IForgotPassword, ILoginPayload, ILoginResponse, IRegisterPayload, IRegisterResponse, IResetPassword, ITransactionResponse, IUserData, IVerifyPayload } from "types/userTypes"
import { IClient, ICompany, IOrder, ITransaction } from "types/tablesTypes";
import { IAddClientData, IAddCompanyData, IClientData, MeshulamPayload, NewDealPayload, PaymentPayload } from "./types";
import apiClient from "./apiClient";
import { FeeSteps } from "types/formTypes";
import axios from "axios";
import { env } from "env";

async function axiosPost<T>(url: string, data: any, customHeaders: any = {}) {
    try {
        const headers = {
            ...apiClient.defaults.headers,
            ...customHeaders,
        };

        const result = await apiClient.post<T>(url, data, { headers });
        return result.data;
    } catch (exception: any) {
        if (exception.response?.data) {
            throw exception.response.data;
        }
        throw exception;
    }
}


async function axiosGet<T>(url: string) {
    try {
        const result = await apiClient.get<T>(url);
        return result.data;
    } catch (exception: any) {
        if (exception.response?.data) {
            throw exception.response.data;
        }
        throw exception;
    }
}

async function axiosPut<T>(url: string, data: any) {
    try {
        const result = await apiClient.put<T>(url, data);
        return result.data;
    } catch (exception: any) {
        if (exception.response?.data) {
            throw exception.response.data;
        }
        throw exception;
    }
}

async function axiosDelete<T>(url: string, customHeaders: any = {}) {
    try {
        const headers = {
            ...apiClient.defaults.headers,
            ...customHeaders,
        };

        const result = await apiClient.delete<T>(url, { headers });
        return result.data;
    } catch (exception: any) {
        if (exception.response?.data) {
            throw exception.response.data;
        }
        throw exception;
    }
}



export function apiLogin(payload: ILoginPayload) {
    return axiosPost<ILoginResponse>(`${endPoints.LOGIN}`, payload);
}

export function apiVerify(payload: IVerifyPayload) {
    return axiosPost<ILoginResponse>(`${endPoints.VERIFY}`, payload);
}

export function apiGetUserDetails() {
    return axiosGet<IUserData>(`${endPoints.USER}/me?populate=*`);
}

export function apiGetTransactions(page: number, filter?: string | null, exclude?: string | null) {
    let url = `${endPoints.TRANSACTION}?pagination[page]=${page}&populate=*`;
    if (filter) {
        url += `&filters[transactionStep][$eq]=${filter}`;
    }

    if (exclude) {
        url += `&filters[transactionStep][$ne]=${exclude}`;
    }

    return axiosGet<ITransactionResponse>(url);
}

export function apiUpdateTransaction(id: number, payload: any) {
    return axiosPut<ITransactionResponse>(`${endPoints.TRANSACTION}/${id}`, { data: payload })
}

export function apiSearchTransaction(searchQuery: string) {
    return axiosGet<any>(`${endPoints.TRANSACTION}?_q=${searchQuery}`)
}

export function apiRegister(payload: IRegisterPayload) {
    return axiosPost<IRegisterResponse>(`${endPoints.REGISTER}`, payload)
}


export function apiForgotPassword(payload: IForgotPassword) {
    return axiosPost<any>(`${endPoints.FORGOT_PASSWORD}`, payload)
}

export function apiResetPassword(payload: IResetPassword) {
    return axiosPost<any>(`${endPoints.RESET_PASSWORD}`, payload)
}

export function apiGetAllClients() {
    return axiosGet<IClientData>(`${endPoints.CLIENT}?pagination[limit]=1000`).then(response => {
        return response.data.map(client => {
            const { id, ...attributes } = client.attributes;
            return { id: client.id, ...attributes };
        });
    });
}

export function apiGetPropertyTypes() {
    return axiosGet<any>(`${endPoints.PROPERTY_TYPE}`);
}


export function apiGetCheckList() {
    return axiosGet<any>(`${endPoints.CHECK_LIST}`);
}

export async function apiGetClients(page: number) {
    try {
        const response = await await axiosGet<IClientData>(`${endPoints.CLIENT}?pagination[page]=${page}`);
        const clients = response.data.map(client => {
            const { id, ...attributes } = client.attributes;
            return { id: client.id, ...attributes };
        });
        return clients;
    } catch (error) {
        console.error("Error fetching clients:", error);
        throw error;
    }
}

export function apiAddClient(payload: IClient) {
    return axiosPost<IAddClientData>(`${endPoints.CLIENT}`, { data: payload });
}

export function apiUpdateClient(id: number, payload: any) {
    return axiosPut<IAddClientData>(`${endPoints.CLIENT}/${id}`, { data: payload })
}

export function apiDeleteClient(id: number) {
    return axiosDelete<any>(`${endPoints.CLIENT}/${id}`)
}


export function apiAddOrder(payload: IOrder) {
    return axiosPost<any>(`${endPoints.ORDER}`, { data: payload });
}

export function apiUpdateOrder(id: number, payload: any) {
    return axiosPut<any>(`${endPoints.ORDER}/${id}`, { data: payload })
}


export async function apiCreatePayment(payload: MeshulamPayload): Promise<any> {
    console.log(endPoints.PAYMENT);
    
    try {
        const formData = new FormData();

        Object.entries(payload).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
                formData.append(key, value);
            }
        });

        const response = await axios.post(
            `${env.API_URL}${endPoints.PAYMENT}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error creating payment:', error);
        throw error;
    }
}


export function apiAddStepPayment(payload: FeeSteps) {
    return axiosPost<any>(`${endPoints.STEPS}`, { data: payload });
}

export function apiAddOtherPartyClient(payload: IClient) {
    return axiosPost<IAddClientData>(`${endPoints.OTHER_CLIENT}`, { data: payload });
}

export function apiAddCompany(payload: ICompany) {
    return axiosPost<IAddCompanyData>(`${endPoints.COMPANY}`, { data: payload })
}

export function apiDeleteCompany(id: number) {
    return axiosDelete<any>(`${endPoints.COMPANY}/${id}`)
}

export function apiEditUser(payload: IUserData, userId: string) {
    return axiosPut<IUserData>(`${endPoints.USER}/${userId}`, payload);
}

export function apiCreateTransaction(payload: NewDealPayload) {
    return axiosPost<any>(`${endPoints.TRANSACTION}`, { data: payload })
}

export function apiCreateTransactionPayment(payload: PaymentPayload) {
    return axiosPost<unknown>(`${endPoints.PAYMENTS}`, { data: payload })
}


export async function uploadFile(formData: FormData) {
    const headers = {
        'Content-Type': 'multipart/form-data',
    };

    return axiosPost<any>('/upload', formData, headers);
}