import styles from './Button.module.css'

type ButtonInputs = {
    text: string;
    onClick?: () => void;
    type?: 'button' | 'submit' | 'reset';
}

export default function Button({ text, type, onClick }: ButtonInputs) {
    return (
        <button onClick={onClick}  type={type} className={type === 'reset' ? styles.redButton : styles.button}>
            <span>{text}</span>
        </button>
    )
}