import React, { Fragment, useState } from 'react';
import styles from './Tabs.module.css';
import { Excel, PDF, Print } from 'assets';

type Tab = {
  title: string | React.ReactNode;
  content: React.ReactNode;
};

type TabsProps = {
  tabs: Tab[];
  showIcons?: boolean;
  navigateTo?: number | null
};

const Tabs: React.FC<TabsProps> = ({ tabs, showIcons = true, navigateTo }) => {
  const getFirstContentTab = () => navigateTo || tabs.findIndex((tab) => tab.content);
  const [activeTab, setActiveTab] = useState(getFirstContentTab);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabTitles}>
        <div>
          {tabs.map((tab, index) => (
            <button
              key={index}
              disabled={!tab.content}
              onClick={() => handleTabClick(index)}
              className={`${!tab.content && 'font-weight-bold'} ${styles.tabTitle} ${activeTab === index ? styles.active : ''}`}
            >
              {tab.title}
            </button>
          ))}
        </div>
        {/* {showIcons && (<div className={styles.tabIcons}>
          <button>
            <Print /> הדפסה
          </button>
          <button>
            <PDF /> שמירה כPDF
          </button>
          <button>
            <Excel /> יצואXL
          </button>
        </div>)} */}
      </div>
      <div className={styles.tabContent}>
        {tabs[activeTab].content}
      </div>
    </div>
  );
};

export default Tabs;
