import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { InputAdornment, IconButton } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { Calendar } from 'assets'; 
interface CustomDatePickerProps {
    name: string;
    control: any;
    rules?: any;
    defaultValue?: Date | null;
    label: string;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
    name,
    control,
    rules,
    defaultValue = null,
    label
}) => {
    const [open, setOpen] = useState(false); 

    const handleIconClick = () => {
        setOpen(true); 
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={rules}
                render={({ field, fieldState }) => (
                    <div className="d-flex gap-2 align-items-center">
                        <label>{label}</label>
                        <DatePicker
                            {...field}
                            value={field.value || null}
                            onChange={field.onChange} 
                            format="dd/MM/yyyy"
                            open={open} 
                            onClose={() => setOpen(false)}
                            slotProps={{
                                textField: {
                                    InputProps: {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleIconClick}>
                                                    <Calendar />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    },
                                },
                            }}
                        />
                    </div>
                )}
            />
        </LocalizationProvider>
    );
};

export default CustomDatePicker;
